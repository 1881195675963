module.exports = {
  allProjects: [
    // {
    //   icon: 'Folder',
    //   iconRepo: 'GitHub',
    //   linkRepo: '',
    //   iconProject: 'External',
    //   linkProject: '',
    //   urlImage: 'https://imgur.com/gjtfJtk.png',
    //   title: '',
    //   description: '',
    //   language: '',
    //   category: '',
    // },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/game-library',
      iconProject: 'External',
      linkProject: 'https://pleigames-catalog.vercel.app/',
      urlImage: 'https://imgur.com/c4AP3Hb.png',
      title: 'Catálogo Pessoal de Jogos',
      description: 'Desenvolvi este projeto para organizar todos os meus jogos de Xbox que tenho. Utilizei o React Icons para obter alguns ícones e peguei como base o site de jogos da Xbox (Microsoft). Lá tem meu perfil de gamer e de meu filho, além de uma página para vendas de jogos usados.',
      language: 'JavaScript · ReactJS · React Icons · React Router DOM · Styled Components · HTML · Yarn',
      category: '#PleiGames · #pessoal · #games · #catalogo · #jogos · #xbox',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/desafio-gama-landingpage-reactjs',
      iconProject: 'External',
      linkProject: 'https://pleigames.vercel.app/',
      urlImage: 'https://imgur.com/Z7zE7Ok.png',
      title: 'PleiGames - Landing Page E-commerce',
      description: 'Este projeto foi um desafio do Bootcamp Hiring Coders da VTEX em 2021, onde tínhamos que criar uma landing page e armazenar os dados do cadastro da Newsletter da Black Friday no localStorage em JSON. No menu "Minha Conta" tem a Gestão de Cadastros de Clientes e Produtos.',
      language: 'JavaScript · CSS3 · HTML5 · ReactJS · React Icons · React Router DOM · Styled Components · Yarn',
      category: '#landingpage · #ecommerce · #bootcamp · #HiringCoders · #VTEX',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/chatbotfit-telegram-dio',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Chatbot Fit Telegram',
      description: 'Desenvolvido no Bootcamp Node.JS Web Developer da Digital Innovation One em 2021, projeto de “Chatbot no Telegram com JavaScript e NodeJS” para praticar e aplicar os conceitos de integração e buscas de vídeos de exercícios físicos no YouTube utilizando uma plataforma de entendimento de linguagem natural chamada DialogFlow.',
      language: 'JavaScript · NodeJS · API Telegram',
      category: '#API · #APITelegram · #ChatBot · #BotFather · #DialogFlow',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/serverless-sexy-api-dio',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Serverless Sexy API',
      description: 'Desenvolvido no Bootcamp Node.JS Web Developer da Digital Innovation One em 2021, projeto de “APIs para Gestão de Produtos utilizando Node.js” para praticar e aplicar os conceitos de desenvolvimento de APIs e Arquitetura Serverless com Node.js.',
      language: 'JavaScript · NodeJS · Azure Functions',
      category: '#API · #ArquiteturaServerless',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/transferencia-bancaria-dio',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Transferências Bancárias - PleiBank',
      description: 'Desenvolvido no Bootcamp .NET Fundamentals da Digital Innovation One em 2021, projeto com algoritmo simples de transferência bancária para exercer o pensamento orientado a objetos, o principal paradigma de programação utilizada no mercado.',
      language: 'C# · .NET Core',
      category: '#PleiBank · #bank · #TransferenciasBancarias · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/cadastro-series-dio',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'APP para Cadastro de Séries',
      description: 'Desenvolvido no Bootcamp .NET Fundamentals da Digital Innovation One em 2021, neste projeto criado em um algoritmo simples de cadastro de séries para praticar conhecimentos de orientação a objetos, o principal paradigma de programação utilizada no mercado.',
      language: 'C# · .NET Core',
      category: '#CadastroDeSeries · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/mapeamento-covid19-js',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Mapeamento Covid-19 pelo Mundo',
      description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2021, neste projeto mantenha-se informado a respeito de todos os casos do COVID19 pelo mundo criando uma "Progressive Web Application (PWA)" utilizando React e aprenda como criar uma aplicação para ser executada no browser.',
      language: 'JavaScript · HTML5',
      category: '#API · #Covid19 · #MapeamentoCovid19 · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/game-space-shooter-dio',
      iconProject: 'External',
      linkProject: 'https://game-space-shooter.vercel.app/',
      urlImage: 'https://imgur.com/J3Wvl9F.png',
      title: 'Jogo Space Shooter',
      description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, neste projeto trabalhei com posicionamento no CSS e lógica de programação utilizando posicionamento com CSS, manipulação do DOM, eventListeners, e manipulação de Array.',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #JogoSpaceShooter · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/jogo-da-velha-dio',
      iconProject: 'External',
      linkProject: 'https://jogo-velha.vercel.app/',
      urlImage: 'https://imgur.com/9BKkR85.png',
      title: 'Jogo da Velha',
      description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, neste projeto do Jogo da Velha, criado utilizando somente HTML, CSS e Javascript para criarmos toda suas interações, desde a escolha do jogador, efetivar uma jogada e dar um vencedor ao jogo',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #JogoDaVelha · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/game-memoria-mario-dio',
      iconProject: 'External',
      linkProject: 'https://game-memoria-mario.vercel.app/',
      urlImage: 'https://imgur.com/bbLFrQb.png',
      title: 'Jogo da Memória - Mário',
      description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, neste projeto, com poucas linhas de código, trabalhei com efeitos 3D no CSS e lógica de programação utilizando condicionais, Immediately Invoked Function Expression e manipulação de Array em um projeto super divertido.',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #Mario · #Memoria · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/game-memoria-genius-dio',
      iconProject: 'External',
      linkProject: 'https://game-genius.vercel.app/',
      urlImage: 'https://imgur.com/3m3om0w.png',
      title: 'Jogo da Memória - Genius',
      description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, nesse projeto, o desafio foi de criar um jogo Gênesis utilizando apenas HTML, CSS e JavaScript de forma introdutória, sendo o principal conceito, CSS Grid, manipulação de Array e Arrow Functions',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #Genius · #Memoria · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/game-de-naves-dio',
      iconProject: 'External',
      linkProject: 'https://game-resgate.vercel.app/',
      urlImage: 'https://imgur.com/CDgUKj9.png',
      title: 'Jogo de Naves - Resgate',
      description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, no projeto utilizando a didática passo a passo em seu desenvolvimento, especificamente, os jogos serão publicados de forma que possam ser acessados via browser',
      language: 'JavaScript · jQuery · HTML5 · CSS3',
      category: '#games · #jogos · #JogoDeNaves  · #Notepad++ · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/api-previsao-tempo-angular',
      iconProject: 'External',
      linkProject: 'https://api-previsao-tempo.vercel.app/',
      urlImage: 'https://imgur.com/bGc7uT4.png',
      title: 'PleiWeather - Previsão do Tempo',
      description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2021, neste projeto com o objetivo é evoluir mais ainda os conhecimentos em Angular e desenvolvimento Web de uma forma avançada de gerenciamento de estado com NGRX, práticas e padrões de desenvolvimento, acompanhados de algumas APIs úteis que o Angular tem.',
      language: 'TypeScript · JavaScript · HTML5 · SASS-SCSS · Angular · OpenWeather',
      category: '#API · #OpenWeather · #PrevisaoDoTempo · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/clone-mercadolivre-dio',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Mercado Livre | Clone',
      description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei um aplicativo semelhante ao Mercado Livre baseando-se em React Native e Firebase, desenvolvendo do zero a estrutura de front-end com React Native e toda a interface utilizando Autentications.',
      language: 'JavaScript · React Native · StyleSheet · React Navigation · Expo · Firebase',
      category: '#clone · #ui · #ux · #interface · #MercadoLivre · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/clone-ifood-dio',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Interface do iFood | Clone',
      description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei a interface do aplicativo iFood, com a listagem de restaurantes, carrousel e categorias. Utilizando React Native, Hooks, React Navigation e uma API estática de JSON dos restaurantes no app.',
      language: 'JavaScript · Style Components · React Native · React Navigation · Expo',
      category: '#clone · #ui · #ux · #API · #APIEstatica · #iFood · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/cloneInstagramDIO',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Interface e Feed do Instagram | Clone',
      description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto fiz o clone da interface da página e de feed de postagens Instagram, utilizando React Native, Hooks, React Navigation e um Back-end com apenas um JSON das publicações no app.',
      language: 'JavaScript · Style Components · React Native · React Navigation · EsLint · Axios · JSON Server',
      category: '#clone · #ui · #ux · #interface · #Instagram · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/game-dinossauro-dio',
      iconProject: 'External',
      linkProject: 'https://game-dinossauro.vercel.app/',
      urlImage: 'https://imgur.com/yuShrpY.png',
      title: 'Jogo do Dinossauro do Google - Sem internet',
      description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, quem nunca ficou sem internet quando mais precisava, e para distrair começou a jogar aquele joguinho do dinossauro em um deserto? Pois é, divirta-se jogando ele novamente.',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #JogoDoDinossauro · #Google · #responsive · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/snake-game-javascript',
      iconProject: 'External',
      linkProject: 'https://game-snake.vercel.app/',
      urlImage: 'https://imgur.com/IBayh0k.png',
      title: 'Jogo da Cobrinha - Snake Game',
      description: 'Desenvolvido no Bootcamp HTML Developer da Digital Innovation One em 2020, o jogo relembra ao antigo game nos celulares com tecnologias TDMA/CDMA/GSM com aquelas telas verdes e outras brancas (quem tinha com tela branca era rei, rsrs). Foi o máximo reviver aquela época, principalmente codar e jogar.',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #JogoDaCobrinha · #SnakeGame · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/ui-clone-typeform-rocketseat',
      iconProject: 'External',
      linkProject: 'https://uiclone-typeform.vercel.app/',
      urlImage: 'https://imgur.com/8c6Gftr.png',
      title: 'Home Typeform | Clone',
      description: 'UI focado na animação da homepage da Typeform. Não contém nenhuma imagem do site, focado apenas na animação do scroll. Código produzido durante o vídeo "Homepage Animada do Typeform com Framer Motion" do canal no YouTube da Rocketseat.',
      language: 'TypeScript · HTML5 · Style Components · Framer Motion',
      category: '#clone · #ui · #ux · #interface · #HomeTypeform · #Typeform · #responsive · #Rocketseat · #UI Clone · #YouTube',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/ui-clone-vercel-rocketseat',
      iconProject: 'External',
      linkProject: 'https://uiclone-vercel.vercel.app/',
      urlImage: 'https://imgur.com/aesHnph.png',
      title: 'Home Vercel | Clone',
      description: 'Primeiro UI Clone realizado com o time da Rocketseat pelo YouTube. UI focado na animação da página. Não contém header e footer, apenas a parte principal do site. A responsividade foi dada como desafio, e alguns elementos ficaram idênticos a página real da Vercel.',
      language: 'HTML5 · CSS3 · SASS-SCSS',
      category: '#clone · #ui · #ux · #interface · #HomeVercel · #Vercel · #responsive · #Rocketseat · #UI Clone · #YouTube',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/my-personal-portfolio',
      iconProject: 'External',
      linkProject: 'https://pleitersonv1.vercel.app/',
      urlImage: 'https://imgur.com/Veb3zkj.png',
      title: 'Portfólio Pessoal - 1ª versão',
      description: 'Com tudo o que aprendi com as Imersões da Alura e a NLW da Rocketseat fiz meu primeiro Portfólio em 2021, ainda com pouco conhecimento consegui fazer muito mais do que esperava. Projeto pessoal para organizar todas as minhas informações pessoais e profissionais como dev.',
      language: 'TypeScript · HTML5 · CSS3 · ReactJS · NodeJS · Yarn',
      category: '#portfolio · #pessoal · #dev · #responsive',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/clone-interface-netflix-html-css-js',
      iconProject: 'External',
      linkProject: 'https://clone-netflix.vercel.app/',
      urlImage: 'https://imgur.com/4bQ76Wj.png',
      title: 'Interface Netflix | Clone',
      description: 'Desenvolvido no Bootcamp HTML Web Developer da Digital Innovation One em 2020, neste projeto aprendi como estruturar um layout, técnicas de CSS3 com containers e variáveis, como posicionar os elementos com Flexbox e como utilizar plugins Jquery a favor da sua aplicação.',
      language: 'JavaScript · HTML5 · CSS3 · jQuery (Owl Carousel 2)',
      category: '#clone · #ui · #ux · #interface · #Netflix · #responsive · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/clone-home-instagram-html-css',
      iconProject: 'External',
      linkProject: 'https://clone-instagram.vercel.app/',
      urlImage: 'https://imgur.com/gmBBze7.png',
      title: 'Home do Instagram | Clone',
      description: 'Esta foi meu primeiro projeto realizando um clone de outra página. Desenvolvido no Bootcamp HTML Web Developer da Digital Innovation One em 2020, neste projeto criei a página de login do Instagram abordado o conceito sobre CSS Flexbox e responsividade.',
      language: 'HTML5 · CSS3',
      category: '#clone · #ui · #ux · #interface · #HomeInstagram · #Instagram · #responsive · #bootcamp · #dio · #DigitalInnovationOne',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/nlw2-discovery-proffy',
      iconProject: '',
      linkProject: 'https://pleiterson-me.vercel.app/404',
      urlImage: 'https://imgur.com/gjtfJtk.png',
      title: 'Proffy',
      description: 'Minha primeira participação nos projetos realizados pela Rocketseat. Desenvolvido na NLW2 em 2020, como já faz alguns anos que não tinha contato com HTML e CSS que nunca tive contato, e mesmo assim resolvi realizar esta a Trilha Dicovery para rever, conhecer e entender mais sobre CSS.',
      language: 'JavaScript · HTML5 · CSS3 · NodeJS · Express · Nodemon · Nunjucks · SQLite (sqlite-async)',
      category: '#Proffy · #Rocketseat · #NextLevelWeek · #nlw2 · #Discovery',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/imersao-reactjs-pleiflix',
      iconProject: 'External',
      linkProject: 'https://pleiflix.vercel.app/',
      urlImage: 'https://imgur.com/iHYizS7.png',
      title: 'PleiFlix',
      description: 'Meu segundo projeto com o JavaScript e o entendimento foi um pouco complicado, mas nada que vários dias estudando o código e a comunidade, resolveria a grande bagunça que estava minha cabeça, rsrs. Desenvolvido na Imersão ReactJS da Alura em 2020, nesta imersão eu realizei a PleiFlix.',
      language: 'JavaScript · HTML5 · CSS3 · NodeJS · ReactJS · Style Components · React Router DOM · React Slick (Carousel) · EsLint',
      category: '#PleiFlix · #Alura · #ImersaoReactJS · #imersao · #responsive',
    },
    {
      icon: 'Folder',
      iconRepo: 'GitHub',
      linkRepo: 'https://github.com/Pleiterson/imersaogamedev-javascript',
      iconProject: 'External',
      linkProject: 'https://abruxahipsta.vercel.app/',
      urlImage: 'https://imgur.com/BA4dAsB.png',
      title: 'As Aventuras da Bruxa Hipsta',
      description: 'Este foi o meu primeiro contato com JavaScript, consagrando meu retorno aos estudos em programação. Desenvolvido na Imersão GameDev da Alura em 2020, o jogo foi desenvolvido na plataforma online p5*js Web Editor. A medida que a Bruxa percorre a floresta, a pontuação aumenta.',
      language: 'JavaScript · HTML5 · CSS3',
      category: '#games · #jogos · #Hipsta · #Alura · #ImersaoGameDev · #imersao · #p5JSWebEditor',
    }
  ],

  // apis: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   iconProject: 'External',
  //   //   linkProject: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '#API',
  //   // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/chatbotfit-telegram-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Chatbot Fit Telegram',
  //     description: 'Desenvolvido no Bootcamp Node.JS Web Developer da Digital Innovation One em 2021, projeto de “Chatbot no Telegram com JavaScript e NodeJS” para praticar e aplicar os conceitos de integração e buscas de vídeos de exercícios físicos no YouTube utilizando uma plataforma de entendimento de linguagem natural chamada DialogFlow.',
  //     language: 'JavaScript · NodeJS · API Telegram',
  //     category: '#API · #APITelegram · #ChatBot · #BotFather · #DialogFlow',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/serverless-sexy-api-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Serverless Sexy API',
  //     description: 'Desenvolvido no Bootcamp Node.JS Web Developer da Digital Innovation One em 2021, projeto de “APIs para Gestão de Produtos utilizando Node.js” para praticar e aplicar os conceitos de desenvolvimento de APIs e Arquitetura Serverless com Node.js.',
  //     language: 'JavaScript · NodeJS · Azure Functions',
  //     category: '#API · #ArquiteturaServerless',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/mapeamento-covid19-js',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Mapeamento Covid-19 pelo Mundo',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2021, neste projeto mantenha-se informado a respeito de todos os casos do COVID19 pelo mundo criando uma "Progressive Web Application (PWA)" utilizando React e aprenda como criar uma aplicação para ser executada no browser.',
  //     language: 'JavaScript · HTML5',
  //     category: '#API · #Covid19 · #MapeamentoCovid19 · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/api-previsao-tempo-angular',
  //     iconProject: 'External',
  //     linkProject: 'https://api-previsao-tempo.vercel.app/',
  //     title: 'Previsão do Tempo',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2021, neste projeto com o objetivo é evoluir mais ainda os conhecimentos em Angular e desenvolvimento Web de uma forma avançada de gerenciamento de estado com NGRX, práticas e padrões de desenvolvimento, acompanhados de algumas APIs úteis que o Angular tem.',
  //     language: 'TypeScript · JavaScript · HTML5 · SASS-SCSS · Angular · OpenWeather',
  //     category: '#API · #OpenWeather · #PrevisaoDoTempo · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-ifood-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Interface do iFood | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei a interface inicial do aplicativo iFood, com a listagem de restaurantes, carrousel da banners e categorias. Utilizando React Native, os Hooks do React para controle de estado, React Navigation para roteamento de telas na aplicação e integrando a uma API estática com apenas um JSON para listagem dos restaurantes no app',
  //     language: 'JavaScript · Style Components · React Native · React Navigation · Expo',
  //     category: '#clone · #ui · #ux · #API · #APIEstatica · #iFood · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   }
  // ],

  // clones: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   iconProject: 'External',
  //   //   linkProject: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '#clone · #ui · #ux · #interface',
  //   // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-mercadolivre-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Mercado Livre | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei um aplicativo semelhante ao Mercado Livre baseando-se em React Native e Firebase, desenvolvendo do zero a estrutura de front-end com React Native e toda a interface utilizando Autentications.',
  //     language: 'JavaScript · React Native · StyleSheet · React Navigation · Expo · Firebase',
  //     category: '#clone · #ui · #ux · #interface · #MercadoLivre · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-ifood-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Interface do iFood | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei a interface inicial do aplicativo iFood, com a listagem de restaurantes, carrousel da banners e categorias. Utilizando React Native, os Hooks do React para controle de estado, React Navigation para roteamento de telas na aplicação e integrando a uma API estática com apenas um JSON para listagem dos restaurantes no app',
  //     language: 'JavaScript · Style Components · React Native · React Navigation · Expo',
  //     category: '#clone · #ui · #ux · #API · #APIEstatica · #iFood · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/cloneInstagramDIO',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Interface e Feed do Instagram | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto fiz o clone da interface da página de feed de postagens do app Instagram, utilizando React Native, os Hooks do React para controle de estado, a versão 5 do React Navigation para roteamento de telas na aplicação, a lib Animated do React Native e um Back-end com apenas um JSON para listagem das publicações no app.',
  //     language: 'JavaScript · Style Components · React Native · React Navigation · EsLint · Axios · JSON Server',
  //     category: '#clone · #ui · #ux · #interface · #Instagram · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/ui-clone-typeform-rocketseat',
  //     iconProject: 'External',
  //     linkProject: 'https://uiclone-typeform.vercel.app/',
  //     title: 'Home Typeform | Clone',
  //     description: 'UI focado na animação da homepage da Typeform. Não contém nenhuma imagem do site, focado apenas na animação do scroll. Código produzido durante o vídeo "Homepage Animada do Typeform com Framer Motion" do canal no YouTube da Rocketseat.',
  //     language: 'TypeScript · HTML5 · Style Components · Framer Motion',
  //     category: '#clone · #ui · #ux · #interface · #HomeTypeform · #Typeform · #responsive · #Rocketseat · #UI Clone · #YouTube',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/ui-clone-vercel-rocketseat',
  //     iconProject: 'External',
  //     linkProject: 'https://uiclone-vercel.vercel.app/',
  //     title: 'Home Vercel | Clone',
  //     description: 'Primeiro UI Clone realizado com o time da Rocketseat pelo YouTube. UI focado na animação da página. Não contém header e footer, apenas a parte principal do site. A responsividade foi dada como desafio, e alguns elementos ficaram idênticos a página real da Vercel.',
  //     language: 'HTML5 · CSS3 · SASS-SCSS',
  //     category: '#clone · #ui · #ux · #interface · #HomeVercel · #Vercel · #responsive · #Rocketseat · #UI Clone · #YouTube',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-interface-netflix-html-css-js',
  //     iconProject: 'External',
  //     linkProject: 'https://clone-netflix.vercel.app/',
  //     title: 'Interface Netflix | Clone',
  //     description: 'Desenvolvido no Bootcamp HTML Web Developer da Digital Innovation One em 2020, neste projeto aprendi como estruturar um layout, técnicas de CSS3 com containers e variáveis, como posicionar os elementos com Flexbox e como utilizar plugins Jquery a favor da sua aplicação.',
  //     language: 'JavaScript · HTML5 · CSS3 · jQuery (Owl Carousel 2)',
  //     category: '#clone · #ui · #ux · #interface · #Netflix · #responsive · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-home-instagram-html-css',
  //     iconProject: 'External',
  //     linkProject: 'https://clone-instagram.vercel.app/',
  //     title: 'Home do Instagram | Clone',
  //     description: 'Esta foi meu primeiro projeto realizando um clone de outra página. Desenvolvido no Bootcamp HTML Web Developer da Digital Innovation One em 2020, neste projeto criei a página de login do Instagram abordado o conceito sobre CSS Flexbox e responsividade.',
  //     language: 'HTML5 · CSS3',
  //     category: '#clone · #ui · #ux · #interface · #HomeInstagram · #Instagram · #responsive · #bootcamp · #dio · #DigitalInnovationOne',
  //   }
  // ],

  // ecommerce: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   iconProject: 'External',
  //   //   linkProject: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '#ecommerce',
  //   // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/desafio-gama-landingpage-reactjs',
  //     iconProject: 'External',
  //     linkProject: 'https://pleigames.vercel.app/',
  //     title: 'Landing Page E-commerce',
  //     description: 'Este projeto foi um desafio do Bootcamp Hiring Coders da VTEX em 2021, onde tínhamos que criar uma landing page e armazenar os dados do cadastro da Newsletter da Black Friday no localStorage em JSON. No menu "Minha Conta" tem a Gestão de Cadastros de Clientes e Produtos.',
  //     language: 'JavaScript · CSS3 · HTML5 · ReactJS · React Icons · React Router DOM · Styled Components · Yarn',
  //     category: '#landingpage · #ecommerce · #bootcamp · #HiringCoders · #VTEX',
  //   }
  // ],

  // games: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   iconProject: 'External',
  //   //   linkProject: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '#games · #jogos',
  //   // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/game-space-shooter-dio',
  //     iconProject: 'External',
  //     linkProject: 'https://game-space-shooter.vercel.app/',
  //     title: 'Jogo estilo Space Shooter',
  //     description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, neste projeto trabalhei com posicionamento no CSS e lógica de programação utilizando posicionamento com CSS, manipulação do DOM, eventListeners, e manipulação de Array.',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #JogoSpaceShooter · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/jogo-da-velha-dio',
  //     iconProject: 'External',
  //     linkProject: 'https://jogo-velha.vercel.app/',
  //     title: 'Jogo da Velha',
  //     description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, neste projeto do Jogo da Velha, criado utilizando somente HTML, CSS e Javascript para criarmos toda suas interações, desde a escolha do jogador, efetivar uma jogada e dar um vencedor ao jogo',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #JogoDaVelha · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/game-memoria-mario-dio',
  //     iconProject: 'External',
  //     linkProject: 'https://game-memoria-mario.vercel.app/',
  //     title: 'Jogo da Memória - Mário',
  //     description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, neste projeto, com poucas linhas de código, trabalhei com efeitos 3D no CSS e lógica de programação utilizando condicionais, Immediately Invoked Function Expression e manipulação de Array em um projeto super divertido.',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #Mario · #Memoria · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/game-memoria-genius-dio',
  //     iconProject: 'External',
  //     linkProject: 'https://game-genius.vercel.app/',
  //     title: 'Jogo da Memória - Genius',
  //     description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, nesse projeto, o desafio foi de criar um jogo Gênesis utilizando apenas HTML, CSS e JavaScript de forma introdutória, sendo o principal conceito, CSS Grid, manipulação de Array e Arrow Functions',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #Genius · #Memoria · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/game-de-naves-dio',
  //     iconProject: 'External',
  //     linkProject: 'https://game-resgate.vercel.app/',
  //     title: 'Jogo de Naves - Resgate',
  //     description: 'Desenvolvido no Bootcamp JavaScript Game Developer da Digital Innovation One em 2021, no projeto utilizando a didática passo a passo em seu desenvolvimento, especificamente, os jogos serão publicados de forma que possam ser acessados via browser',
  //     language: 'JavaScript · jQuery · HTML5 · CSS3',
  //     category: '#games · #jogos · #JogoDeNaves  · #Notepad++ · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/game-dinossauro-dio',
  //     iconProject: 'External',
  //     linkProject: 'https://game-dinossauro.vercel.app/',
  //     title: 'Jogo do Dinossauro do Google - Sem internet',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, quem aqui nunca ficou sem internet naquele momento inoportuno e para distrair começou a jogar aquele joguinho do dinossauro em um deserto? Pois é, divirta-se jogando ele novamente, e agora com sua conexão de internet ativa rsrs.',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #JogoDoDinossauro · #Google · #responsive · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/snake-game-javascript',
  //     iconProject: 'External',
  //     linkProject: 'https://game-snake.vercel.app/',
  //     title: 'Jogo da Cobrinha - Snake Game',
  //     description: 'Desenvolvido no Bootcamp HTML Developer da Digital Innovation One em 2020, o jogo relembra ao antigo game nos celulares com tecnologias TDMA/CDMA/GSM com aquelas telas verdes e outras brancas (quem tinha com tela branca era rei, rsrs). Foi o máximo reviver aquela época, principalmente codar e jogar.',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #JogoDaCobrinha · #SnakeGame · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/imersaogamedev-javascript',
  //     iconProject: 'External',
  //     linkProject: 'https://abruxahipsta.vercel.app/',
  //     title: 'As Aventuras da Bruxa Hipsta',
  //     description: 'Este foi o meu primeiro contato com JavaScript consagrando meu retorno aos estudos em programação. Desenvolvido na Imersão GameDev da Alura em 2020, o jogo mostra uma pequena bruxinha perdida na floresta, onde ela tem que desviar de seus inimigos. A medida que a Bruxa percorre a floresta, a pontuação aumenta.',
  //     language: 'JavaScript · HTML5 · CSS3',
  //     category: '#games · #jogos · #Hipsta · #Alura · #ImersaoGameDev · #imersao · #p5JSWebEditor',
  //   }
  // ],

  // landingPages: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   iconProject: 'External',
  //   //   linkProject: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '#landingpage',
  //   // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/desafio-gama-landingpage-reactjs',
  //     iconProject: 'External',
  //     linkProject: 'https://pleigames.vercel.app/',
  //     title: 'Landing Page E-commerce',
  //     description: 'Este projeto foi um desafio do Bootcamp Hiring Coders da VTEX em 2021, onde tínhamos que criar uma landing page e armazenar os dados do cadastro da Newsletter da Black Friday no localStorage em JSON. No menu "Minha Conta" tem a Gestão de Cadastros de Clientes e Produtos.',
  //     language: 'JavaScript · CSS3 · HTML5 · ReactJS · React Icons · React Router DOM · Styled Components · Yarn',
  //     category: '#landingpage · #ecommerce · #bootcamp · #HiringCoders · #VTEX',
  //   },
  // ],

  // mobile: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '#mobile',
  //   // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-mercadolivre-dio',
  //     title: 'Mercado Livre | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei um aplicativo semelhante ao Mercado Livre baseando-se em React Native e Firebase, desenvolvendo do zero a estrutura de front-end com React Native e toda a interface utilizando Autentications.',
  //     language: 'JavaScript · React Native · StyleSheet · React Navigation · Expo · Firebase',
  //     category: '#clone · #ui · #ux · #interface · #MercadoLivre · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/clone-ifood-dio',
  //     title: 'Interface do iFood | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto criei a interface inicial do aplicativo iFood, com a listagem de restaurantes, carrousel da banners e categorias. Utilizando React Native, os Hooks do React para controle de estado, React Navigation para roteamento de telas na aplicação e integrando a uma API estática com apenas um JSON para listagem dos restaurantes no app',
  //     language: 'JavaScript · Style Components · React Native · React Navigation · Expo',
  //     category: '#clone · #ui · #ux · #API · #APIEstatica · #iFood · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/cloneInstagramDIO',
  //     title: 'Interface e Feed do Instagram | Clone',
  //     description: 'Desenvolvido no Bootcamp React Native Mobile Developer da Digital Innovation One em 2020, neste projeto fiz o clone da interface da página de feed de postagens do app Instagram, utilizando React Native, os Hooks do React para controle de estado, a versão 5 do React Navigation para roteamento de telas na aplicação, a lib Animated do React Native e um Back-end com apenas um JSON para listagem das publicações no app.',
  //     language: 'JavaScript · Style Components · React Native · React Navigation · EsLint · Axios · JSON Server',
  //     category: '#clone · #ui · #ux · #interface · #Instagram · #mobile · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  // ],

  // projects: [
  //   // {
  //   //   icon: 'Folder',
  //   //   iconRepo: 'GitHub',
  //   //   linkRepo: '',
  //   //   iconProject: 'External',
  //   //   linkProject: '',
  //   //   title: '',
  //   //   description: '',
  //   //   language: '',
  //   //   category: '',
  //   // },
    // {
    //   icon: 'Folder',
    //   iconRepo: 'GitHub',
    //   linkRepo: 'https://github.com/Pleiterson/game-library',
    //   iconProject: 'External',
    //   linkProject: 'https://pleigames-catalog.vercel.app/',
    //   title: 'Catálogo Pessoal de Jogos',
    //   description: 'Desenvolvi este projeto para organizar todos os meus jogos de Xbox que tenho. Utilizei o React Icons para obter alguns ícones e peguei como base o site de jogos da Xbox (Microsoft). Lá tem meu perfil de gamer e de meu filho, além de uma página para vendas de jogos usados.',
    //   language: 'JavaScript · ReactJS · Styled Components · HTML · Yarn',
    //   category: '#projeto',
    // },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/transferencia-bancaria-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Transferências Bancárias - Plei Bank',
  //     description: 'Desenvolvido no Bootcamp .NET Fundamentals da Digital Innovation One em 2021, projeto com algoritmo simples de transferência bancária para exercer o pensamento orientado a objetos, o principal paradigma de programação utilizada no mercado.',
  //     language: 'C# · .NET Core',
  //     category: '#PleiBank · #bank · #TransferenciasBancarias · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/cadastro-series-dio',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'APP para Cadastro de Séries',
  //     description: 'Desenvolvido no Bootcamp .NET Fundamentals da Digital Innovation One em 2021, neste projeto criado em um algoritmo simples de cadastro de séries para praticar conhecimentos de orientação a objetos, o principal paradigma de programação utilizada no mercado.',
  //     language: 'C# · .NET Core',
  //     category: '#CadastroDeSeries · #bootcamp · #dio · #DigitalInnovationOne',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/my-personal-portfolio',
  //     iconProject: 'External',
  //     linkProject: 'https://pleitersonv1.vercel.app/',
  //     title: 'Portfólio Pessoal - 1ª versão',
  //     description: 'Com tudo o que aprendi com as Imersões da Alura e a NLW da Rocketseat fiz meu primeiro Portfólio em 2021, ainda com pouco conhecimento consegui fazer muito mais do que esperava. Projeto pessoal para organizar todas as minhas informações pessoais e profissionais como dev.',
  //     language: 'TypeScript · HTML5 · CSS3 · ReactJS · NodeJS · Yarn',
  //     category: '#portfolio · #pessoal · #dev · #responsive',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/nlw2-discovery-proffy',
  //     iconProject: '',
  //     linkProject: 'https://pleiterson-me.vercel.app/404',
  //     title: 'Proffy',
  //     description: 'Minha primeira participação nos projetos realizados pela Rocketseat. Desenvolvido na NLW2 em 2020, como já faz alguns anos que não tinha contato com HTML e muito menos CSS que nunca vi, e que mesmo vendo na Imersão GameDev e ReactJS da Alura, resolvi realizar primeiro esta a Trilha Dicovery para rever, conhecer e entender mais sobre CSS.',
  //     language: 'JavaScript · HTML5 · CSS3 · NodeJS · Express · Nodemon · Nunjucks · SQLite (sqlite-async)',
  //     category: '#Proffy · #Rocketseat · #NextLevelWeek · #nlw2 · #Discovery',
  //   },
  //   {
  //     icon: 'Folder',
  //     iconRepo: 'GitHub',
  //     linkRepo: 'https://github.com/Pleiterson/imersao-reactjs-pleiflix',
  //     iconProject: 'External',
  //     linkProject: 'https://pleiflix.vercel.app/',
  //     title: 'PleiFlix',
  //     description: 'Foi o meu primeiro contato verdadeiramente com o JavaScript e o entendimento foi um pouco complicado, mas nada que vários dias estudando o código e a comunidade, resolveria a grande bagunça que estava minha cabeça, rsrs. Desenvolvido na Imersão ReactJS da Alura em 2020, nesta imersão eu realizei a PleiFlix.',
  //     language: 'JavaScript · HTML5 · CSS3 · NodeJS · ReactJS · Style Components · React Router DOM · React Slick (Carousel) · EsLint',
  //     category: '#PleiFlix · #Alura · #ImersaoReactJS · #imersao · #responsive',
  //   }
  // ],
};
