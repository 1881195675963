import React from 'react';
import Routers from '../_config/routers';
import '../_config/GlobalStyle.css';

function App() {
  return (
    <Routers />
  );
}

export default App;
